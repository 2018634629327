import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import * as commons from '@/commons'
import router from '@/router'

/*global Vue, router, i18n */

//modules
import indoorClimate from './modules/indoorClimate'
import graphLocation from './modules/graphs/graphLocation'
import graphLocationComparison from './modules/graphs/graphLocationComparison'
import graphMeteringPoints from './modules/graphs/graphMeteringPoints'
import orgAdmin from './modules/admin/orgAdmin'
import analytics from './modules/analytics'
import hourlyHeatmaps from './modules/hourlyHeatmaps'
import feedback from './modules/feedback'
import messageCentre from './modules/messageCentre'
import organisation from './modules/organisation'
import userManagement from './modules/userManagement'
import buildings from './modules/buildings'
import devices from "@/store/modules/devices";
import integrations from './modules/integrations'
import profiles from './modules/profiles'
import schedulers from './modules/schedulers'
import shareData from './modules/shareData'
import liveViewShare from './modules/liveViewShare'
import systemAdmin from './modules/system'
import testExternalApi from './modules/test/testExternalApi'
import reporting from './modules/reporting'
import energySavings from './modules/energySavings'
import buildingLogs from './modules/buildingLogs'
import i18n from "@/i18n"
import _ from "lodash"
import statistics from "@/store/modules/statistics";
import dashboard from './modules/dashboard'
import dashboardTable from './modules/dashboardTable'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const getDefaultState = () => {
    return {
        loggedIn: false,
        userInfo: {
            settings: {
                lang: 'en'
            }
        },
        contextOrgOptions: [],
        contextOrg: null,
        contextBuilding: null,
        systemParams: {
            notificationTimeoutSec: 5,
            defaultLocationProfileParams: {
                temperature: [20, 21, 23, 24],
                humidity: [20, 30, 60, 70],
                co2: [1000, 2000],
                noise: [50, 70]
            },
            defaultSchedulerProfileSettings: {
                1: {
                    startHours: [7, 9, 17, 21],
                    temperatures: [18, 21, 21, 21, 18]
                },
                2: {
                    startHours: [7, 9, 17, 21],
                    temperatures: [18, 21, 21, 21, 18]
                },
                3: {
                    startHours: [7, 9, 17, 21],
                    temperatures: [18, 21, 21, 21, 18]
                },
                4: {
                    startHours: [7, 9, 17, 21],
                    temperatures: [18, 21, 21, 21, 18]
                },
                5: {
                    startHours: [7, 9, 17, 21],
                    temperatures: [18, 21, 21, 21, 18]
                },
                6: {
                    startHours: [7, 9, 17, 21],
                    temperatures: [18, 21, 21, 21, 18]
                },
                7: {
                    startHours: [7, 9, 17, 21],
                    temperatures: [18, 21, 21, 21, 18]
                },
            },
            preferredSensorOrder: ['temperature', 'humidity', 'co2', 'barometric_pressure', 'noise', 'voc', 'tvoc', 'light', 'light_level', 'people_counter_total', 'region_count_total', 'motion', 'closed', 'door_closed', 'meter_energy_consumption', 'meter_supplied_energy', 'meter_consumed_energy', 'meter_co2_emission', 'meter_co2_emission_total', 'active_devices']   
        },
        errorPage: {
            title: "",
            description: ""
        },
        messageObj: {},
        error: '',
        connectionError: false,
        showGlobalLoader: false,
        showInPageLoader: false,
        desktopNotifications: {},
        windowInFocus: true
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    isAuthenticated: state => {
        return Vue.$keycloak.authenticated
    },
    isLoggedIn: state => {
        return state.loggedIn
    },
    getLang: state => {
        return state.userInfo.settings.lang
    }
}

const actions = {

    appInit({ commit, state, dispatch }, initData) {

        console.log('appInit')
        dispatch('indoorClimate/appInit')
        dispatch('graphLocation/appInit')
        dispatch('graphLocationComparison/appInit')
        dispatch('graphMeteringPoints/appInit')
        dispatch('analytics/appInit')
        dispatch('hourlyHeatmaps/appInit')
        dispatch('feedback/appInit')
        dispatch('messageCentre/appInit')
        dispatch('orgAdmin/appInit')
        dispatch('systemAdmin/appInit')
        dispatch('testExternalApi/appInit')
        dispatch('reporting/appInit')
        dispatch('buildingLogs/appInit')
        dispatch('energySavings/appInit')

        //attach logout ajax call if window is left
        $(window).on("focus", function (event) {
            commit('UPDATE_WINDOW_IN_FOCUS', true)
        }.bind(this))
            .on("blur", function (event) {
                commit('UPDATE_WINDOW_IN_FOCUS', false)
            }.bind(this))

        let existingHandler = window.onunload
        window.onunload = function (event) {
            console.log('window.onunload logout...')
            if (existingHandler) { existingHandler(event) }
            dispatch('clearApp')
        }.bind(this)
    },

    loggedIn({ commit, state, dispatch }) {

        console.log('loggedIn')
        dispatch('saveKeycloakData')
        dispatch('getBackendInfo')
    },

    saveKeycloakData({ commit, state, dispatch }) {

        console.log('saveKeycloakData')

        localStorage.setItem("vue-token", Vue.$keycloak.token)
        localStorage.setItem("vue-refresh-token", Vue.$keycloak.refreshToken)

        commit('SAVE_TOKEN_DATA', {
            kc_username: Vue.$keycloak.tokenParsed.preferred_username,
            kc_name: Vue.$keycloak.tokenParsed.name,
            kc_idToken: Vue.$keycloak.idToken,
            kc_accessToken: Vue.$keycloak.token
        })
    },

    getBackendInfo({ commit, state, dispatch }) {

        console.log('getBackendInfo')

        Vue.axios.get('/users/initialize').then(response => {
            let data = response.data
            if (data.userInfo) {

                if(data.userInfo.authorities == null || data.userInfo.authorities.length == 0){

                    commit('SET_ERROR_PAGE', i18n.t('common.errors.noAuth'), { root: true })
                    router.push({ path: `/errorGeneric` })
                    return
                }

                commit('SET_BACKEND_INFO', data)

                // context org automatic selection logic
                if (commons.hasRole('ADMIN')) {
                    Vue.axios.get('/admin/organisations').then(response => {
                        let contextOrgOptions = _.sortBy(response.data.filter(org => {
                                return org.status == 'ACTIVE'
                            }), [function (o) {
                                return o.name;
                            }]
                        )
                        commit('SET_CONTEXT_ORG_OPTIONS', contextOrgOptions)

                        if (contextOrgOptions.length > 0) {
                            if (!state.userInfo.settings.hasOwnProperty('contextOrg') || !contextOrgOptions.some(org => org.id === state.userInfo.settings.contextOrg)) {
                                dispatch('setContextOrg', contextOrgOptions[0])
                            } else {
                                commit('SET_CONTEXT_ORG', contextOrgOptions.find(org => org.id === state.userInfo.settings.contextOrg))
                            }
                        }
                    }).catch(error => {
                        commons.processRestError(error)
                    })
                } else {
                    let contextOrgOptions = commons.getUserAuthorities().filter(auth => {
                        return auth.authorizationTargetPath.organisation && auth.authorizationTargetPath.organisation.status == 'ACTIVE'
                    }).map(auth => {
                        return auth.authorizationTargetPath.organisation
                    })
                    contextOrgOptions = _.sortBy(commons.getUniqueListBy(contextOrgOptions, "id"), [function (o) {
                        return o.name;
                    }])

                    commit('SET_CONTEXT_ORG_OPTIONS', contextOrgOptions)

                    if (contextOrgOptions.length > 0) {
                        if (!state.userInfo.settings.hasOwnProperty('contextOrg') || !contextOrgOptions.some(org => org.id === state.userInfo.settings.contextOrg)) {
                            dispatch('setContextOrg', contextOrgOptions[0])
                        } else {
                            commit('SET_CONTEXT_ORG', contextOrgOptions.find(org => org.id === state.userInfo.settings.contextOrg))
                        }
                    }
                }

            } else {
                commit('SET_ERROR_PAGE', i18n.t('common.errors.500'), { root: true })
                router.push({ path: `/errorGeneric` })
            }

        }).catch(error => {
            commons.processRestError(error)
            commit('SET_ERROR_PAGE', i18n.t('common.errors.500'), { root: true })
            router.push({ path: `/errorGeneric` })
        })
    },

    reloadContextOrgOptionsForAdmin({commit}){

        console.log('reloadContextOrgOptionsForAdmin')

        Vue.axios.get('/admin/organisations').then(response => {
            let contextOrgOptions = _.sortBy(response.data.filter(org => {
                    return org.status == 'ACTIVE'
                }), [function (o) {
                    return o.name;
                }]
            )
            commit('SET_CONTEXT_ORG_OPTIONS', contextOrgOptions)

            if(contextOrgOptions.some(org => org.id === state.contextOrg.id)) {
                commit('SET_CONTEXT_ORG', contextOrgOptions.filter(org => org.id === state.contextOrg.id)[0])
            }
        }).catch(error => {
            commons.processRestError(error)
        })
    },

    logout({ commit, state, dispatch, getters }) {

        console.log('logout')

        let logoutFromBackendCallback = function () {
            Vue.$keycloak.logout().then(function (success) {
                console.log("keycloak logout success ", success )
                dispatch('clearApp')
                window.location.reload()
            }.bind(this)).catch(function (error) {
                console.log("keycloak logout error ", error )
                dispatch('clearApp')
                window.location.reload()
            }.bind(this))
        }.bind(this)

        let confirmCallback = function () {
            Vue.axios.get('/users/logout').then(function (response) {
                console.error('logout from backend successfully')
                logoutFromBackendCallback()
            }.bind(this))
                .catch(function (error) {
                    console.error('logout from backend error', error)
                    logoutFromBackendCallback()
                }.bind(this))
        }.bind(this)

        commons.confirmDialog({
            title: i18n.t('logout'),
            text: i18n.t('logoutConfirmation'),
            callbackYes: confirmCallback.bind(this)
        })
    },

    forceLogout({ commit, state, dispatch, getters }) {

        console.log('logout')

        let logoutFromBackendCallback = function () {
            Vue.$keycloak.logout().then(function (success) {
                console.log("keycloak logout success ", success )
                dispatch('clearApp')
                window.location.reload()
            }.bind(this)).catch(function (error) {
                console.log("keycloak logout error ", error )
                dispatch('clearApp')
                window.location.reload()
            }.bind(this))
        }.bind(this)

        
        Vue.axios.get('/users/logout').then(function (response) {
            console.error('logout from backend successfully')
            logoutFromBackendCallback()
        }.bind(this))
            .catch(function (error) {
            console.error('logout from backend error', error)
            logoutFromBackendCallback()
        }.bind(this))
    },

    clearApp({ commit, state, dispatch }) {

        console.log('clearApp')

        commit('indoorClimate/GLOBAL_RESET')
        commit('graphLocation/GLOBAL_RESET')
        commit('graphLocationComparison/GLOBAL_RESET')
        commit('graphMeteringPoints/GLOBAL_RESET')
        commit('analytics/GLOBAL_RESET')
        commit('hourlyHeatmaps/GLOBAL_RESET')
        commit('orgAdmin/GLOBAL_RESET')
        commit('LOGOUT')

        /*GoogleMapsLoader.release(function () {
            console.log('google maps api unloaded')
        })*/
    },

    updateUserSettings({ commit, state, dispatch }, userSettings) {

        console.log('updateUserSettings ' + JSON.stringify(userSettings))

        let send = userSettings.send ? userSettings.send : false

        commit('UPDATE_USER_SETTINGS', userSettings)

        if (send) {

            let userForUpdate = { settings: this.state.userInfo.settings }

            Vue.axios.patch(`/users/${state.userInfo.id}/settings`, userForUpdate).then(response => {
                console.log('updateUserSettings backend done')
            }).catch(error => {
                commons.processRestError(error)
            })
        }
    },

    set_lang({ commit, state, dispatch }, lang) {

        console.log('set_lang ' + lang)

        if (getters.isAuthenticated) {
            dispatch('updateUserSettings', { lang: lang, send: true })
        } else {
            commit('SET_LANGUAGE', lang)
            i18n.locale = lang
        }
    },

    setContextOrg({ commit, state, dispatch }, org) {

        console.log('setContextOrg ' + org.id)

        if (getters.isAuthenticated) {
            dispatch('updateUserSettings', { contextOrg: org.id, send: true })
        }
        commit('SET_CONTEXT_ORG', org)
    },

    showGlobalLoader({ commit }, data) {
        commit('UPDATE_SHOW_GLOBAL_LOADER', data)
    },

    showInPageLoader({ commit }, data) {
        commit('UPDATE_SHOW_IN_PAGE_LOADER', data)
    },

    showDesktopNotification({ commit }, data) {

        let notificationTimeout = state.systemParams.notificationTimeoutSec ? state.systemParams.notificationTimeoutSec * 1000 : 8000

        let windowInFocus = data.ignoreWindowInFocus ? false : state.windowInFocus

        if (state.userInfo.settings.desktopNotificationsEnabled && commons.desktopNotificationsSupported() && !windowInFocus) {

            let notificationUuid = data.uuid
            let _this = this

            let desktopNotification = new Notification(data.text, {
                body: data.body,
                icon: data.icon,
                requireInteraction: data.requireInteraction
            })
            desktopNotification.onclick = function () {
                window.focus()
                _this.dispatch('removeNotification', notificationUuid)
                desktopNotification.close()
            }.bind(this)

            if (!data.requireInteraction) {
                setTimeout(function () {
                    _this.dispatch('removeNotification', notificationUuid)
                    desktopNotification.close()
                }.bind(this), notificationTimeout)
            }

            commit('ADD_DESKTOP_NOTIFICATION', { uuid: data.uuid, data: desktopNotification })
        }
    },

    removeNotification({ commit }, uuid) {
        commit('REMOVE_DESKTOP_NOTIFICATION', uuid)
    },

    showLogoutNotification({ dispatch }, requireInteraction) {
        dispatch('showDesktopNotification', {
            text: i18n.t('desktopNotification.logout.text'),
            body: i18n.t('desktopNotification.logout.body'),
            icon: require('@/assets/custom/img/logout.png'),
            uuid: new Date().getTime(),
            requireInteraction: requireInteraction,
            ignoreWindowInFocus: true
        }, { root: true })
    },
}

// mutations
const mutations = {

    ['SAVE_TOKEN_DATA']: (state, data) => {
        state.userInfo = Object.assign({}, state.userInfo, data)
    },

    ['SET_SYSTEM_PARAMS']: (state, data) => {
        state.systemParams = Object.assign({}, state.systemParams, data)
    },

    ['SET_BACKEND_INFO']: (state, data) => {
        state.userInfo = Object.assign({}, state.userInfo, data.userInfo)
        state.loggedIn = true
        if (state.userInfo.settings == null) {
            state.userInfo.settings = {}
        }
        if (!state.userInfo.settings.hasOwnProperty('lang')) {
            state.userInfo.settings.lang = 'en'
            state.userInfo.settings.messagesFrequency = 'WEEKLY'
            state.userInfo.settings.messagesSeverityThreshold = 'MAJOR'
        }
        i18n.locale = state.userInfo.settings.lang
        state.systemParams = Object.assign({}, state.systemParams, data.systemParams)
    },

    ['SET_LANGUAGE']: (state, language) => {
        state.userInfo.settings.lang = language
    },

    ['SET_CONTEXT_ORG_OPTIONS']: (state, orgs) => {
        state.contextOrgOptions = orgs
    },

    ['SET_CONTEXT_ORG']: (state, org) => {
        state.contextOrg = org
        state.contextBuilding = null
    },

    ['SET_CONTEXT_BUILDING']: (state, org) => {
        state.contextBuilding = org
    },

    ['SET_ERROR_PAGE']: (state, data) => {
        state.errorPage = data
    },

    ['SET_ROOT_ERROR']: (state, error) => {
        state.error = error
    },

    ['LOGOUT']: (state) => {
        Object.assign(state, getDefaultState())
        localStorage.removeItem("vue-token")
    },

    ['UPDATE_USER_SETTINGS']: (state, data) => {
        if (data) {
            delete data.send
            state.userInfo.settings = Object.assign({}, state.userInfo.settings, data)
            i18n.locale = state.userInfo.settings.lang
        }
    },

    ['REPLACE_USER_SETTINGS']: (state, data) => {
        if (data) {
            delete data.send
            state.userInfo.settings = data
            i18n.locale = state.userInfo.settings.lang
        }
    },

    ['UPDATE_SHOW_GLOBAL_LOADER']: (state, data) => {
        state.showGlobalLoader = data
    },

    ['UPDATE_SHOW_IN_PAGE_LOADER']: (state, data) => {
        state.showInPageLoader = data
    },

    ['UPDATE_CONNECTION_ERROR']: (state, data) => {
        state.connectionError = data
    },

    ['ADD_DESKTOP_NOTIFICATION']: (state, data) => {
        state.desktopNotifications[data.uuid] = data.data
    },

    ['REMOVE_DESKTOP_NOTIFICATION']: (state, uuid) => {
        delete state.desktopNotifications[uuid]
    },

    'UPDATE_WINDOW_IN_FOCUS': (state, data) => {
        state.windowInFocus = data
    }
}

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        indoorClimate,
        graphLocation,
        graphLocationComparison,
        graphMeteringPoints,
        analytics,
        statistics,
        hourlyHeatmaps,
        feedback,
        messageCentre,
        organisation,
        userManagement,
        buildings,
        devices,
        integrations,
        profiles,
        schedulers,
        shareData,
        liveViewShare,
        orgAdmin,
        systemAdmin,
        testExternalApi,
        reporting,
        buildingLogs,
        energySavings,
        dashboard,
        dashboardTable
    },
    strict: debug,
    plugins: debug ? [createLogger({
        filter(mutation, stateBefore, stateAfter) {
            // returns `true` if a mutation should be logged
            // `mutation` is a `{ type, payload }`
            return false
        }
    })] : []
})
