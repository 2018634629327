<template>
    <div class="kpi-visualization">
        <h6>{{ $t('dashboard.table.headers.tail') }}</h6>
        <div class="visualization-container">
            <div class="year-container">
                <svg :width="radius * 2 + 40" :height="radius * 2 + 40"
                    :viewBox="`${-radius - 20} ${-radius - 20} ${radius * 2 + 40} ${radius * 2 + 40}`">
                    <circle cx="0" cy="0" :r="centerRadius"
                        :fill="year1Data.overall ? colors[year1Data.overall - 1] : '#E2E0E0'" />
                    <text class="overall" x="0" y="0" text-anchor="middle" dy=".3em" 
                        fill="black">
                        {{ getFormattedTail(year1Data.overall) || 'N/A' }}
                    </text>
                    <g v-for="(key, i) in sectionKeys" :key="i">
                        <path :d="generatePath(quarterAngles[i].start, quarterAngles[i].end, radius, centerRadius)"
                            :fill="year1Data[key] ? colors[year1Data[key] - 1] : '#E2E0E0'" stroke="white"
                            stroke-width="4">
                        </path>
                        <text :x="getSectionTextPosition(quarterAngles[i].start, quarterAngles[i].end).x"
                            :y="getSectionTextPosition(quarterAngles[i].start, quarterAngles[i].end).y"
                            text-anchor="middle" dy=".3em" fill="black" font-size="0.9rem" font-weight="600">{{ letters[i] }}
                        </text>
                    </g>
                </svg>
                <h4>{{ selectedYears[0] }}</h4>
            </div>

            <div class="year-container">
                <svg :width="radius * 2 + 40" :height="radius * 2 + 40"
                    :viewBox="`${-radius - 20} ${-radius - 20} ${radius * 2 + 40} ${radius * 2 + 40}`">
                    <circle cx="0" cy="0" :r="centerRadius"
                        :fill="year2Data.overall ? colors[year2Data.overall - 1] : '#E2E0E0'" />
                    <text class="overall" x="0" y="0" text-anchor="middle" dy=".3em" 
                        fill="black">
                        {{ getFormattedTail(year2Data.overall) || 'N/A' }}
                    </text>
                    <g v-for="(key, i) in sectionKeys" :key="i">
                        <path :d="generatePath(quarterAngles[i].start, quarterAngles[i].end, radius, centerRadius)"
                            :fill="year2Data[key] ? colors[year2Data[key] - 1] : '#E2E0E0'" stroke="white"
                            stroke-width="4">
                        </path>
                        <text :x="getSectionTextPosition(quarterAngles[i].start, quarterAngles[i].end).x"
                            :y="getSectionTextPosition(quarterAngles[i].start, quarterAngles[i].end).y"
                            text-anchor="middle" dy=".3em" fill="black" font-size="0.9rem" font-weight="600">{{ letters[i] }}
                        </text>
                    </g>
                </svg>
                <h4>{{ selectedYears[1] }}</h4>
            </div>
        </div>
        <div class="legend">
            <div class="legend-item">
                <div class="color-box" style="background-color: #4A7B57"></div>
                <span>{{ $t('common.category')}} I</span>
            </div>
            <div class="legend-item">
                <div class="color-box" style="background-color: #82A03A"></div>
                <span>{{ $t('common.category')}} II</span>
            </div>
            <div class="legend-item">
                <div class="color-box" style="background-color: #BAC41E"></div>
                <span>{{ $t('common.category')}} III</span>
            </div>
            <div class="legend-item">
                <div class="color-box" style="background-color: #F2E901"></div>
                <span>{{ $t('common.category')}} IV</span>
            </div>
            <div class="legend-item">
                <div class="color-box" style="background-color: #E2E0E0"></div>
                <span>{{ $t('common.noData')}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'KPIVisualization',
    props: {
        building: {
            type: Object,
            required: true
        },
        selectedYears: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            radius: 120,
            centerRadius: 80,
            colors: ['#4A7B57','#82A03A', '#BAC41E', '#F2E901'],
            letters: ['A', 'I', 'L', 'T'],
            sectionKeys: ['acoustic', 'iaq', 'light','thermal'],
            quarterAngles: [
                { start: 0, end: 90 },
                { start: 90, end: 180 },
                { start: 180, end: 270 },
                { start: 270, end: 360 }
            ]
        };
    },

    computed: {
        year1Data() {
            const year = this.selectedYears[0];
            const buildingTailData = this.building?.serverKpis?.find(kpi => kpi.year === year)?.buildingTail;
            return buildingTailData || { overall: 0, sections: [0, 0, 0, 0] };
        },
        year2Data() {
            const year = this.selectedYears[1];
            const buildingTailData = this.building?.serverKpis?.find(kpi => kpi.year === year)?.buildingTail;
            return buildingTailData || { overall: 0, sections: [0, 0, 0, 0] };
        },
    },

    methods: {
        polarToCartesian(centerX, centerY, radius, angleInDegrees) {
            const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
            return {
                x: centerX + (radius * Math.cos(angleInRadians)),
                y: centerY + (radius * Math.sin(angleInRadians))
            };
        },

        generatePath(startAngle, endAngle, outerRadius, innerRadius = 0) {
            const start = this.polarToCartesian(0, 0, outerRadius, endAngle);
            const end = this.polarToCartesian(0, 0, outerRadius, startAngle);
            const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

            const innerStart = this.polarToCartesian(0, 0, innerRadius, endAngle);
            const innerEnd = this.polarToCartesian(0, 0, innerRadius, startAngle);

            return [
                `M ${start.x} ${start.y}`,
                `A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`,
                `L ${innerEnd.x} ${innerEnd.y}`,
                `A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 1 ${innerStart.x} ${innerStart.y}`,
                'Z'
            ].join(' ');
        },

        getSectionTextPosition(startAngle, endAngle) {
            const middleAngle = (startAngle + endAngle) / 2;
            const radius = (this.radius + this.centerRadius) / 2;
            return this.polarToCartesian(0, 0, radius, middleAngle);
        },

        getFormattedTail(value){
            switch(parseInt(value)) {
                case 1:
                return "I"
                case 2:
                return "II"
                case 3:
                return "III"
                case 4:
                return "IV"
                default:
                return "-"
            } 
        }
    }
};
</script>

<style scoped>
.kpi-visualization {
    border: 1px solid #e7eaec;
    padding: 20px;
    border-radius: 4px;
    background: #fff;
    margin-bottom: 20px;
}

.visualization-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.year-container {
    text-align: center;
}

h4 {
    margin-bottom: 10px;
    font-size: 16px;
}

.legend {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 12px;
}

.legend-item {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 0.75rem;
}

.color-box {
    width: 15px;
    height: 15px;
    border-radius: 3px;
}

.overall{
    font-size: 3rem;
}
</style>