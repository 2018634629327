// this will need to be extended as new stuff is added, should this be loaded dynamically?
export const UNIT_CONVERSIONS = {
    g: {
      thresholds: [
        { value: 1000000, unit: 't', divisor: 1000000 },
        { value: 1000, unit: 'kg', divisor: 1000 },
        { value: 0, unit: 'g', divisor: 1 }
      ],
      nextUnit: { g: 'kg', kg: 't' }
    },
    Wh: {
      thresholds: [
        { value: 1000000, unit: 'MWh', divisor: 1000000 },
        { value: 1000, unit: 'kWh', divisor: 1000 },
        { value: 0, unit: 'Wh', divisor: 1 }
      ],
      nextUnit: { Wh: 'kWh', kWh: 'MWh' }
    },
    kWh: {
      thresholds: [
        { value: 1000000, unit: 'GWh', divisor: 1000000 },
        { value: 1000, unit: 'MWh', divisor: 1000 },
        { value: 0, unit: 'kWh', divisor: 1 }
      ],
      nextUnit: { kWh: 'MWh', MWh: 'GWh' }
    },
    W: {
      thresholds: [
        { value: 1000000, unit: 'MW', divisor: 1000000 },
        { value: 1000, unit: 'kW', divisor: 1000 },
        { value: 0, unit: 'W', divisor: 1 }
      ],
      nextUnit: { W: 'kW', kW: 'MW' }
    },
    V: {
      thresholds: [
        { value: 1000, unit: 'kV', divisor: 1000 },
        { value: 0, unit: 'V', divisor: 1 }
      ],
      nextUnit: { V: 'kV' }
    }
  };

  
  //TO-DO: Is there better way to not show .00 instead of replace
  export const formatUnit = (value, baseUnit, forceNextUnit = false) => {
    const conversion = UNIT_CONVERSIONS[baseUnit];
    if (!conversion) {
      const formattedValue = Number(value).toFixed(2).replace(/\.?0+$/, '');
      return { value: formattedValue, unit: baseUnit };
    }
  
    if (forceNextUnit) {
      // Get the threshold for the next unit up
      const nextUnit = conversion.nextUnit[baseUnit];
      if (nextUnit) {
        const threshold = conversion.thresholds.find(t => t.unit === nextUnit);
        if (threshold) {
          const convertedValue = value / threshold.divisor;
          const formattedValue = Number(convertedValue).toFixed(2).replace(/\.?0+$/, '');
          return { value: formattedValue, unit: nextUnit };
        }
      }
    }
  
    // Regular conversion based on thresholds
    const threshold = conversion.thresholds.find(t => value >= t.value);
    if (!threshold) {
      const formattedValue = Number(value).toFixed(2).replace(/\.?0+$/, '');
      return { value: formattedValue, unit: baseUnit };
    }
  
    const convertedValue = value / threshold.divisor;
    const formattedValue = Number(convertedValue).toFixed(2).replace(/\.?0+$/, '');
  
    return {
      value: formattedValue,
      unit: threshold.unit
    };
  };