<template>
  <div class="animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title d-flex justify-content-between align-items-center">
            <h5>System Metrics</h5>
            <div class="d-flex align-items-center">
              <span class="text-muted">Auto-refreshing every minute</span>
              <select v-model="timeRange" class="form-control" style="width: auto">
                <option value="1">Last 1 hour</option>
                <option value="6">Last 6 hours</option>
                <option value="12">Last 12 hours</option>
                <option value="24">Last 24 hours</option>
                <option value="48">Last 48 hours</option>
                <option value="168">Last 7 days</option>
              </select>
            </div>
          </div>
          <div class="ibox-content">
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <div class="row">
              <div class="col-md-6">
                <highcharts :options="directBufferOptions"></highcharts>
              </div>
              <div class="col-md-6">
                <highcharts :options="heapOptions"></highcharts>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <highcharts :options="connectionOptions"></highcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Highcharts from 'highcharts'

export default {
  data() {
    return {
      error: null,
      timeRange: '24',
      refreshInterval: null,
      directBufferOptions: {
        title: { text: 'Direct Buffer Memory' },
        xAxis: { type: 'datetime' },
        yAxis: {
          title: { text: 'Bytes' },
          labels: {
            formatter: function () {
              return (this.value / 1024).toFixed(2) + ' KB';
            }
          }
        },
        tooltip: {
          shared: true,
          formatter: function () {
            return this.points.reduce((s, point) => {
              return s + `<br/>${point.series.name}: ${(point.y / 1024).toFixed(2)} KB`;
            }, `<b>${Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x)}</b>`);
          }
        },
        series: [
          { name: 'Memory Used', data: [] },
          { name: 'Total Capacity', data: [] },
          { name: 'Buffer Count', data: [] }
        ]
      },
      heapOptions: {
        title: { text: 'Heap Memory' },
        xAxis: { type: 'datetime' },
        yAxis: {
          title: { text: 'Bytes' },
          labels: {
            formatter: function () {
              return (this.value / 1024 / 1024).toFixed(2) + ' MB';
            }
          }
        },
        tooltip: {
          shared: true,
          formatter: function () {
            return this.points.reduce((s, point) => {
              return s + `<br/>${point.series.name}: ${(point.y / 1024 / 1024).toFixed(2)} MB`;
            }, `<b>${Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x)}</b>`);
          }
        },
        series: [
          { name: 'Used', data: [] },
          { name: 'Committed', data: [] },
          { name: 'Max', data: [] }
        ]
      },
      connectionOptions: {
        title: { text: 'Database Connections' },
        xAxis: { type: 'datetime' },
        yAxis: {
          title: { text: 'Count' },
          min: 0
        },
        tooltip: { shared: true },
        series: [
          { name: 'Active', data: [] },
          { name: 'Idle', data: [] },
          { name: 'Max', data: [] },
          { name: 'Pending', data: [] }
        ]
      }
    }
  },
  mounted() {
    this.fetchData()
    // Set up auto-refresh interval (60000ms = 1 minute)
    this.refreshInterval = setInterval(() => {
      this.fetchData()
    }, 60000)
  },
  unmounted() {
    // Clean up interval when component is destroyed
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(`/metrics/memory/history?hours=${this.timeRange}`)
        const data = response.data
        this.updateCharts(data)
        this.error = null
      } catch (err) {
        this.error = 'Failed to load metrics data'
        console.error(err)
      }
    },
    updateCharts(data) {
      this.directBufferOptions.series = [
        {
          name: 'Memory Used',
          data: data.directBufferMemoryUsed?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        },
        {
          name: 'Total Capacity',
          data: data.directBufferCapacity?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        },
        {
          name: 'Buffer Count',
          data: data.directBufferCount?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        }
      ]

      this.heapOptions.series = [
        {
          name: 'Used',
          data: data.heapMemoryUsed?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        },
        {
          name: 'Committed',
          data: data.heapMemoryCommitted?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        },
        {
          name: 'Max',
          data: data.heapMemoryMax?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        }
      ]

      this.connectionOptions.series = [
        {
          name: 'Active',
          data: data.activeConnections?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        },
        {
          name: 'Idle',
          data: data.idleConnections?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        },
        {
          name: 'Max',
          data: data.maxConnections?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        },
        {
          name: 'Pending',
          data: data.pendingConnections?.map(m => [m.timestamp, m.value]).sort((a, b) => a[0] - b[0])
        }
      ]
    }
  },
  watch: {
    timeRange() {
      this.fetchData()
    }
  }
}
</script>