import { render, staticRenderFns } from "./WaterDashboard.vue?vue&type=template&id=fadf4ad2&scoped=true"
import script from "./WaterDashboard.vue?vue&type=script&lang=js"
export * from "./WaterDashboard.vue?vue&type=script&lang=js"
import style0 from "./WaterDashboard.vue?vue&type=style&index=0&id=fadf4ad2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fadf4ad2",
  null
  
)

export default component.exports