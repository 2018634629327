import Vue from "vue";

const state = {
    electricityData: null,
    waterData: null,
    heatingData: null,
    selectedPeriod: {
      startDate: null,
      endDate: null
    },
    building: {
      electricityData: null,
      waterData: null,
      heatingData: null,
      selectedPeriod: {
        startDate: null,
        endDate: null
      },
      building_loading: false,
      building_error: null
    },
    loading: false,
    error: null
  }
  
  const mutations = {
    SET_ELECTRICITY_DATA(state, { data, period }) {
      if (!state.electricityData) state.electricityData = { current: null, previous: null };
      state.electricityData[period] = data;
    },
    SET_BUILDING_ELECTRICITY_DATA(state, { data, period }) {
      if (!state.building.electricityData) state.building.electricityData = { current: null, previous: null };
      state.building.electricityData[period] = data;
    },
    SET_WATER_DATA(state, { data, period }) {
      if (!state.waterData) state.waterData = { current: null, previous: null };
      state.waterData[period] = data;
    },
    SET_BUILDING_WATER_DATA(state, { data, period }) {
      if (!state.building.waterData) state.building.waterData = { current: null, previous: null };
      state.building.waterData[period] = data;
    },
    SET_HEATING_DATA(state, { data, period }) {
      if (!state.heatingData) state.heatingData = { current: null, previous: null };
      state.heatingData[period] = data;
    },
    SET_BUILDING_HEATING_DATA(state, { data, period }) {
      if (!state.building.heatingData) state.building.heatingData = { current: null, previous: null };
      state.building.heatingData[period] = data;
    },
    SET_SELECTED_PERIOD(state, { startDate, endDate }) {
      state.selectedPeriod = { startDate, endDate }
    },
    SET_BUILDING_SELECTED_PERIOD(state, { startDate, endDate }) {
      state.building.selectedPeriod = { startDate, endDate }
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_BUILDING_LOADING(state, loading) {
      state.building.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_BUILDING_ERROR(state, error) {
      state.building.error = error;
    }
  }
  
  const actions = {
    async fetchMeteringData({ commit }, { period, ...requestParams }) {
      const { 
        meteringPointSubtype,
        organisationId,
        dateTimeFrom,
        dateTimeTo,
        aggregateWindowDuration,
        aggregateWindowDurationUnit 
      } = requestParams;
  
      try {
        const params = new URLSearchParams({
          meteringPointSubtype,
          dateTimeFrom: dateTimeFrom.toISOString(),
          dateTimeTo: dateTimeTo.toISOString(),
          aggregateWindowDuration,
          aggregateWindowDurationUnit
        });
  
        const response = await Vue.axios.get(
          `/dashboard/organisations/${organisationId}/metering-points-data?${params.toString()}`
        );
  
        let processedData = {};

        // Only process data if we have valid response
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          processedData = response.data.reduce((acc, item) => {
            const { field, time, value } = item;
            if (!field || !time || !value) return acc;
          
            if (!acc[field]) acc[field] = [];
            acc[field].push({
              time,
              value: parseFloat(value) || 0
            });
          
            return acc;
          }, {});
        }

        switch(meteringPointSubtype) {
          case 'ELECTRICITY':
            commit('SET_ELECTRICITY_DATA', { data: processedData, period });
            break;
          case 'WATER':
            commit('SET_WATER_DATA', { data: processedData, period });
            break;
          case 'DISTRICT_HEATING':
            commit('SET_HEATING_DATA', { data: processedData, period });
            break;
        }
        
        return processedData;
      } catch (error) {
        console.error('Error fetching metering data:', error);
        switch(meteringPointSubtype) {
          case 'ELECTRICITY':
            commit('SET_ELECTRICITY_DATA', { data: {}, period });
            break;
          case 'WATER':
            commit('SET_WATER_DATA', { data: {}, period });
            break;
          case 'DISTRICT_HEATING':
            commit('SET_HEATING_DATA', { data: {}, period });
            break;
        }
        commit('SET_ERROR', error);
        throw error;
      }
    },

    async fetchBuildingMeteringData({ commit }, { period, ...requestParams }) {
      const {
        buildingId,
        meteringPointSubtype,
        dateTimeFrom,
        dateTimeTo,
        aggregateWindowDuration,
        aggregateWindowDurationUnit,
      } = requestParams;

      try {
        const params = new URLSearchParams({
          meteringPointSubtype,
          dateTimeFrom: dateTimeFrom.toISOString(),
          dateTimeTo: dateTimeTo.toISOString(),
          aggregateWindowDuration,
          aggregateWindowDurationUnit
        });

        const response = await Vue.axios.get(
          `/dashboard/buildings/${buildingId}/metering-points-data?${params.toString()}`
        );

        let processedData = {};

        // Only process data if we have valid response
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          processedData = response.data.reduce((acc, item) => {
            const { field, time, value } = item;
            if (!field || !time || !value) return acc;
          
            if (!acc[field]) acc[field] = [];
            acc[field].push({
              time,
              value: parseFloat(value) || 0
            });
          
            return acc;
          }, {});
        }

        switch(meteringPointSubtype) {
          case 'ELECTRICITY':
            commit('SET_BUILDING_ELECTRICITY_DATA', { data: processedData, period });
            break;
          case 'WATER':
            commit('SET_BUILDING_WATER_DATA', { data: processedData, period });
            break;
          case 'DISTRICT_HEATING':
            commit('SET_BUILDING_HEATING_DATA', { data: processedData, period });
            break;
        }
        
        return processedData;
      } catch (error) {
        console.error('Error fetching building metering data:', error);
        switch(meteringPointSubtype) {
          case 'ELECTRICITY':
            commit('SET_BUILDING_ELECTRICITY_DATA', { data: {}, period });
            break;
          case 'WATER':
            commit('SET_BUILDING_WATER_DATA', { data: {}, period });
            break;
          case 'DISTRICT_HEATING':
            commit('SET_BUILDING_HEATING_DATA', { data: {}, period });
            break;
        }
        commit('SET_BUILDING_ERROR', error);
        throw error;
      }
    }
  };
    
  const getters = {
    getDataByType: state => type => {
      switch(type) {
        case 'ELECTRICITY': return state.electricityData
        case 'WATER': return state.waterData
        case 'DISTRICT_HEATING': return state.heatingData
        default: return null
      }
    }
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  }